import React from "react";
import { Table, Tag, Empty } from "antd";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Signer, SignerStatus } from "../../services/api";

export interface SignersProps {
  loading: boolean;
  error?: Error | string;
  data: Signer[];
}

const columns = [
  { title: "Name", dataIndex: "name", key: "name" },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status: SignerStatus) => (
      <Tag
        key="status"
        color={status === "SIGNER_ACTIVE" ? "success" : "error"}
        icon={
          status === "SIGNER_ACTIVE" ? (
            <CheckCircleOutlined />
          ) : (
            <CloseCircleOutlined />
          )
        }
      >
        {status === "SIGNER_ACTIVE" ? "active" : "not active"}
      </Tag>
    ),
  },
];

const Signers: React.FC<SignersProps> = ({ loading, data, error }) => {
  const tableElement = (
    <Table<Signer>
      size="small"
      loading={loading}
      dataSource={data}
      pagination={false}
      columns={columns}
    />
  );

  return error ? (
    <Empty description={error && error.toString()} />
  ) : (
    tableElement
  );
};

export default Signers;
