import React from "react";

export function Logo() {
  return (
    <span>
      <b>
        WAVES
        <sup style={{ color: "blue" }}>◆</sup>
      </b>{" "}
      identity
    </span>
  );
}

export default Logo;
