import React from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { Route, Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import AccessDenied from "../../components/access/AccessDenied";
import Navbar from "../../components/navbar/Navbar";
import { getSystemAdmin, Admin, getSystemStatus } from "../../services/api";
import {
  AmplifyAuthenticatorProps,
  CognitoLogout,
  CognitoUser,
} from "../../utils/cognito";
import StatusPage from "../status/Status";
import UsersPage from "../users/Users";

const logout = () => {
  CognitoLogout();
  // TODO: make without reload (like state changed)
  window.location.reload();
};

interface ApplicationState {
  authStatus: "loading" | "ok" | "error";
  cognitoUser?: CognitoUser;
  admin?: Admin;
}

const history = createBrowserHistory();

class Application extends React.Component {
  state: ApplicationState = {
    authStatus: "loading",
  };

  componentDidMount() {
    Auth.currentUserInfo().then(async (user: CognitoUser) => {
      this.setState({ cognitoUser: user });

      try {
        const adminInfo = await getSystemAdmin();
        let updateObject = {
          admin: adminInfo,
        } as ApplicationState;

        if (adminInfo.adminRoles.length) {
          updateObject.authStatus = "ok";
        } else {
          updateObject.authStatus = "error";
        }

        this.setState(updateObject);
      } catch (_) {
        this.setState({
          authStatus: "error",
        });
      }
    });
  }

  render() {
    let content;
    if (this.state.authStatus === "ok") {
      content = (
        <Router history={history}>
          <Navbar onLogout={logout} />

          <Route
            path="/status"
            component={() => (
              <StatusPage
                adminDataProvider={async () => this.state.admin}
                statusDataProvider={getSystemStatus}
              />
            )}
          />
          <Route
              path="/users"
              component={() => <UsersPage/>}
          />
        </Router>
      );
    } else {
      content = (
        <AccessDenied
          onLogout={logout}
          loading={this.state.authStatus === "loading"}
          username={this.state.cognitoUser && this.state.cognitoUser.username}
        />
      );
    }
    return <div className="app">{content}</div>;
  }
}

export default withAuthenticator(Application, AmplifyAuthenticatorProps);
