import {Auth} from "aws-amplify";
import RSA from "node-rsa";
import {configInst} from "../utils/config";

const backendFetch = async (path: string, init?: RequestInit) => {
    const {backendUrl} = await configInst();
    const backendBaseUrl = `${backendUrl}/api`;
    const session = await Auth.currentSession();

    return fetch(`${backendBaseUrl}${path}`, {
        headers: {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
        },
        ...init,
    });
};

export interface Admin {
    username: string;
    adminRoles: string[];
}

export const getSystemAdmin = async (): Promise<Admin> => {
    return await (await backendFetch("/admin")).json();
};

export type SignerStatus = "SIGNER_ACTIVE" | "SIGNER_NOT_ACTIVE";

export interface Signer {
    name: string;
    status: SignerStatus;
}

export type MasterKeyStatus =
    | "SYSTEM_IDLE"
    | "SYSTEM_DISTRIBUTE_NEW_KEYS"
    | "SYSTEM_COLLECT_OLD_KEY"
    | "SYSTEM_REENCRYPTION"
    | "SYSTEM_SIGNERS_ACTIVATION";

export interface Status {
    systemStatus: MasterKeyStatus;
    signers: Signer[];
}

export const getSystemStatus = async (): Promise<Status> => {
    return await (await backendFetch("/status")).json();
};

export interface RegenerationRequest {
    holders: string[];
}

export interface InitRegenerationRequest {
    key_holders: string[];
    k: number;
}

export const initRegeneration = async (
    request: InitRegenerationRequest
): Promise<{}> => {
    return await (
        await backendFetch("/init-regeneration", {
            method: "POST",
            body: JSON.stringify(request),
        })
    ).json();
};

export const getKeyPart = async (): Promise<string> => {
    const rsa = new RSA({b: 1024}).generateKeyPair();
    rsa.setOptions({
        encryptionScheme: "pkcs1_oaep",
        signingScheme: "pkcs1-sha1",
        environment: "browser",
    });

    const response = await (
        await backendFetch(`/get-key-part`, {
            method: "POST",
            body: JSON.stringify({
                encryptionKey: rsa.exportKey("components-public").n.toString("base64"),
            }),
        })
    ).json();

    return rsa.decrypt(response.encryptedKeyPart).toString();
};

export const confirmKeyPart = async (keyPart: string): Promise<any> => {
    const hash = await crypto.subtle.digest(
        "SHA-256",
        await crypto.subtle.digest(
            "SHA-256",
            Buffer.from(keyPart + "KEY_PART_SALT_CRYPTO_PEPE")
        )
    );

    await (
        await backendFetch(`/approve-key-part`, {
            method: "POST",
            body: JSON.stringify({
                hashedKeyPart: new Buffer(hash).toString("base64"),
            }),
        })
    ).json();
};

export const activateSigners = async (keyPart: string): Promise<any> => {
    const response = await (await backendFetch(`/get-encryption-key`)).json();

    const rsa = new RSA({b: 1024});
    rsa.importKey(
        {e: 65537, n: Buffer.from(response.encryptionKey, "base64")},
        "components-public"
    );
    rsa.setOptions({
        encryptionScheme: "pkcs1_oaep",
        signingScheme: "pkcs1-sha1",
        environment: "browser",
    });
    const encryptedKeyPart = rsa.encrypt(Buffer.from(keyPart));

    await (
        await backendFetch(`/set-key-part`, {
            method: "POST",
            body: JSON.stringify({
                encryptedKeyPart: encryptedKeyPart.toString("base64"),
            }),
        })
    ).json();
};

export interface IUser {
    name: string,
    guid: string,
    email: string,
    phone: string,
    address: string,
    publicKey: string,
    verifiedPhone: boolean,
    verifiedEmail: boolean
}

export const findUser = async (request: object): Promise<any> => {
    const response = await (
        await backendFetch(`/find-identity`, {
            method: "POST",
            body: JSON.stringify(request)
        })
    ).json();
    if (response.message) throw new Error(response.message);
    return response;
}

export interface IUserStats {
    estimatedUsers: number,
    confirmedUsers: number,
    unconfirmedUsers: number
}

export const getUserStats = async (): Promise<IUserStats> => {
    return await (
        await backendFetch('/user-stats')
    ).json();
}
