import React from "react";
import { Menu } from "antd";
import {
  TeamOutlined,
  ClusterOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import { Logo } from "../logo/Logo";

export interface NavbarProps {
  onLogout?: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onLogout }) => {
  return (
    <Menu mode="horizontal">
      <Menu.Item key="1" disabled>
        <Logo />
      </Menu.Item>
      <Menu.Item key="2" icon={<TeamOutlined />}>
        <Link to="/users">Users</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<ClusterOutlined />}>
        <Link to="/status">Status</Link>
      </Menu.Item>
      <Menu.Item
        style={{ float: "right" }}
        key="5"
        icon={<LogoutOutlined />}
        onClick={onLogout}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
};

export default Navbar;
