import React from "react";
import { Input } from "antd";

const { Search } = Input;

export interface ActivateFormProps {
  onActivate?: (key: string) => {};
  buttonText: string;
}

const ActivateForm: React.FC<ActivateFormProps> = ({
  onActivate,
  buttonText,
}) => {
  return (
    <Search
      allowClear
      placeholder="Key part"
      enterButton={buttonText}
      onSearch={onActivate}
    />
  );
};

export default ActivateForm;
