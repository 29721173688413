import axios from 'axios';
import { memoize } from './memoize';

interface IConfigData {
    backendUrl: string,
    CognitoSettings?: object
}

export async function getConfig(config: IConfigData) {
    if (!config) {
        const response = await axios.get('/config.json');
        return response.data;
    }
    return config;
}

export const configInst = memoize(getConfig);
