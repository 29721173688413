import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import Application from "./pages/application/Application";
import "./index.css";
import { configInst } from "./utils/config";

configInst().then((config) => {
    Amplify.configure(config.cognitoSettings);
})
    .finally(() => {
        ReactDOM.render(
            <React.StrictMode>
                <Application/>
            </React.StrictMode>,
            document.getElementById("root")
        );
    })
