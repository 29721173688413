import {Typography} from "antd";
import React, {useEffect, useState} from "react";
import {useLocation, useHistory} from "react-router-dom";
import Content from "../../components/content/Content";
import {UserCard} from "../../components/user-card/UserCard";
import {UsersForm} from "../../components/users-form/UsersForm";
import {findUser, getUserStats, IUser, IUserStats} from "../../services/api";

const {Title, Text} = Typography;

export const UsersPage: React.FC = (props) => {
    const [user, setUser] = useState<IUser | null>(null);
    const [userStats, setUserStats] = useState<IUserStats | null>(null)
    const [error, setError] = useState<string | null>(null);
    const location = useLocation();
    const history = useHistory();

    async function fetchUser(searchString: string) {
        setUser(null);
        setError(null);

        if (searchString.length) {
            history.push(`/users?searchString=${searchString}`);
            try {
                const user = await findUser({
                    searchString
                });
                setUser(user);
            } catch (e) {
                setUser(null);
                setError(e.message);
            }
        } else {
            history.push('/users');
        }
    }

    useEffect(() => {
        if (location.search) {
            let params = new URLSearchParams(location.search);
            const searchString = params.get("searchString");
            if (searchString) fetchUser(searchString);
        }
        getUserStats()
            .then((response) => {
                setUserStats(response);
            })
            .catch(e => {
                setUserStats(null)
            });
    }, []);

    return (
        <Content>
            <Title level={5}>Users</Title>
            <Text>
                {userStats && `Users count: confirmed: ${userStats?.confirmedUsers}, unconfirmed: ${userStats.unconfirmedUsers}, estimated: ${userStats?.estimatedUsers}`}
                <br/><br/>
            </Text>
            <UsersForm onFinish={fetchUser}/>
            <br/>
            <br/>
            {error}
            <br/>
            <br/>
            {user && <UserCard {...user}/>}
        </Content>
    );
};

export default UsersPage;
