export function memoize(method: any) {
    let cache: object = {};
    return async function() {
        // @ts-ignore
        let args = JSON.stringify(arguments);
        // @ts-ignore
        cache[args] = cache[args] || method.apply(this, arguments);
        // @ts-ignore
        return cache[args];
    };
}
