import React, { useState } from "react";
import { Button, Typography } from "antd";
import { DataProvider } from "../../utils/data-provider";

const { Text } = Typography;

export interface FetcherProps {
  onFetch?: DataProvider<string | undefined>;
  buttonText: string;
}

const Fetcher: React.FC<FetcherProps> = ({ onFetch, buttonText }) => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          setLoading(true);
          onFetch &&
            onFetch()
              .then((data) => data && setData(data))
              .finally(() => setLoading(false));
        }}
        loading={loading}
      >
        {buttonText}
      </Button>
      <br />
      <br />
      <Text>{data}</Text>
    </>
  );
};

export default Fetcher;
