import React from "react";
import { Form, Button, Input, InputNumber } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import "./reissue-form.css";
import { InitRegenerationRequest } from "../../services/api";

export interface ActivateFormProps {
  onReissue?: (result: InitRegenerationRequest) => Promise<any>;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

const ReissueForm: React.FC<ActivateFormProps> = ({ onReissue }) => {
  return (
    <Form name="reissue_form" onFinish={onReissue}>
      <Form.List
        name="key_holders"
        rules={[
          {
            validator: async (_, holders, k) => {
              if (!holders || holders.length < 2) {
                return Promise.reject(new Error("At least 2 holders"));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? "Holders" : ""}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input holders or delete this field.",
                    },
                  ]}
                  noStyle
                >
                  <Input
                    placeholder="holder username"
                    style={{ width: "60%" }}
                  />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "60%" }}
                icon={<PlusOutlined />}
              >
                Add holder
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item
        name="k"
        initialValue={2}
        rules={[
          {
            validator: async (_, k) => {
              if (k < 2) {
                return Promise.reject(new Error("k > 1"));
              }
            },
          },
        ]}
      >
        <InputNumber defaultValue={2} min={2} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Reissue
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ReissueForm;
