import React, {useState} from "react";
import Search from "antd/lib/input/Search";

interface IUsersForm {
    onFinish: (result: any) => Promise<any>
}

export const UsersForm: React.FC<IUsersForm> = ({onFinish}) => {
    const [loading, setLoading] = useState<boolean>(false);

    async function onSearch(searchQueryString: string) {
        setLoading(true);
        await onFinish(searchQueryString);
        setLoading(false);
    }

    return <>
        <Search
            allowClear
            size="large"
            placeholder="Query"
            enterButton="Search"
            loading={loading}
            onSearch={onSearch}
        />
    </>
}

export default UsersForm;
