import React from "react";
import {Card, Tag} from "antd";
import {IUser} from "../../services/api";

export const UserCard: React.FC<IUser> = ({name, guid, email, phone, address, publicKey, verifiedPhone, verifiedEmail}) => {
    return (
        <Card title={name}>
            email: {email} <Tag color={verifiedEmail ? 'green': 'red'}>{verifiedEmail ? 'verified': 'unverified'}</Tag>
            <br/>
            guid: {guid}
            <br/>
            phone: {phone} <Tag color={verifiedPhone ? 'green': 'red'}>{verifiedPhone ? 'verified': 'unverified'}</Tag>
            <br/>
            address: {address}
            <br/>
            publicKey: {publicKey}
            <br/>
        </Card>
    );
}

export default UserCard;
