import React from "react";
import { Result, Skeleton, Button, Typography } from "antd";
import ActivateForm from "../activate-form/ActivateForm";
import ReissueForm from "../reissue-form/ReissueForm";
import {
  getKeyPart,
  InitRegenerationRequest,
  MasterKeyStatus,
} from "../../services/api";
import Fetcher from "../fetcher/Fetcher";

export interface MasterKeyProps {
  status: MasterKeyStatus;
  loading?: boolean;
  reissued?: boolean;
  activated?: boolean;
  onReissue?: (request: InitRegenerationRequest) => Promise<any>;
  onActivate?: (request: string) => Promise<any>;
  onConfirm?: (request: string) => Promise<any>;
}

const { Text } = Typography;

const titleMap: Record<MasterKeyStatus, string> = {
  SYSTEM_IDLE: "RUNNING",
  SYSTEM_REENCRYPTION: "REENCRYPTION",
  SYSTEM_COLLECT_OLD_KEY: "COLLECT OLD KEY",
  SYSTEM_DISTRIBUTE_NEW_KEYS: "DISTRIBUTE NEW KEYS",
  SYSTEM_SIGNERS_ACTIVATION: "SIGNERS ACTIVATION",
};
const subTitleMap: Record<MasterKeyStatus, string> = {
  SYSTEM_IDLE: "The backend does nothing with the master key.",
  SYSTEM_REENCRYPTION:
    "Database re-encryption. It may take a few minutes. Please wait.",
  SYSTEM_COLLECT_OLD_KEY:
    "The old key is being assembled to start the re-encryption process.",
  SYSTEM_DISTRIBUTE_NEW_KEYS:
    "Distribution of keys to all holders is made. The holder must confirm his part of the key.",
  SYSTEM_SIGNERS_ACTIVATION:
    "Signers are being activated. Please do not make any action. Just wait.",
};

const makeReissueForm = (
  onReissue?: (request: InitRegenerationRequest) => Promise<any>
) => (
  <>
    <Text disabled>Reissue form</Text>
    <br />
    <ReissueForm onReissue={onReissue} />
  </>
);

class MasterKey extends React.Component<MasterKeyProps> {
  render() {
    let extras: React.ReactNode[] = [];

    if (this.props.status === "SYSTEM_IDLE") {
      extras.push(
        <>
          <Text disabled>Signers activation form</Text>
          <ActivateForm
            buttonText="Activate signers"
            onActivate={this.props.onActivate}
          />
          <br />
          <br />
        </>
      );
    }
    if (this.props.status === "SYSTEM_IDLE" && this.props.reissued) {
      extras.push(makeReissueForm(this.props.onReissue));
    }
    if (this.props.status === "SYSTEM_DISTRIBUTE_NEW_KEYS") {
      extras.push(
        <>
          <Fetcher buttonText="Get key part" onFetch={getKeyPart} />
          <br />
          <br />
        </>
      );
      extras.push(
        <>
          <Text disabled>Confirm activation form</Text>
          <ActivateForm
            buttonText="Confirm key part"
            onActivate={this.props.onConfirm}
          />
          <br />
          <br />
        </>
      );
    }

    const content = (
      <Skeleton loading={this.props.loading}>
        <Result
          status={this.props.status === "SYSTEM_IDLE" ? "info" : "404"}
          title={titleMap[this.props.status] || "NOT VALID MASTER KEY STATUS"}
          subTitle={
            subTitleMap[this.props.status] || "NOT VALID MASTER KEY STATUS"
          }
          extra={<>{extras}</>}
        />
      </Skeleton>
    );

    return content;
  }
}

export default MasterKey;
