import React from "react";
import { Result, Button, Tag } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

export interface AccessDeniedProps {
  username?: string;
  onLogout?: () => void;
  loading?: boolean;
}

const loadingMessage = "Please wait for check authorization.";
const accessDeniedMessage =
  "You are not a server administrator. Please contact the gods to obtain the necessary rights.";

const AccessDenied: React.FC<AccessDeniedProps> = ({
  onLogout,
  username,
  loading,
}) => {
  return (
    <Result
      status="403"
      title={loading ? "CHECK AUTHORIZATION" : "ACCESS DENIED"}
      subTitle={loading ? loadingMessage : accessDeniedMessage}
      extra={
        loading ? (
          <></>
        ) : (
          <>
            <Tag color={username ? "default" : "error"}>
              {username || "WITHOUT USERNAME"}
            </Tag>
            <br />
            <br />
            <Button type="primary" icon={<LogoutOutlined />} onClick={onLogout}>
              Logout
            </Button>
          </>
        )
      }
    />
  );
};

export default AccessDenied;
