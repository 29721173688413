import React, { useState, useEffect } from "react";
import { Tag, Typography } from "antd";

import Signers from "../../components/signers/Signers";
import { DataProvider } from "../../utils/data-provider";
import MasterKey from "../../components/masterkey/MasterKey";
import Content from "../../components/content/Content";
import {
  activateSigners,
  Admin,
  confirmKeyPart,
  initRegeneration,
  InitRegenerationRequest,
  MasterKeyStatus,
  Signer,
  Status,
} from "../../services/api";

const { Title, Text } = Typography;

export interface StatusPageProps {
  statusDataProvider?: DataProvider<Status | undefined>;
  adminDataProvider?: DataProvider<Admin | undefined>;
}

interface StatusPageState {
  admin?: Admin;
  signersLoading: boolean;
  masterKeyLoading: boolean;
  signers: Signer[];
  masterKeyStatus: MasterKeyStatus;
  signersError?: Error;
}

class StatusPage extends React.Component<StatusPageProps> {
  state: StatusPageState = {
    signersLoading: true,
    masterKeyLoading: true,
    signers: [],
    masterKeyStatus: "SYSTEM_IDLE",
  };

  updateStatus = async () => {
    if (this.props.statusDataProvider) {
      try {
        const status = await this.props.statusDataProvider();
        this.setState({
          signers: status?.signers,
          masterKeyStatus: status?.systemStatus,
          signersLoading: false,
          masterKeyLoading: false,
        });
      } catch (e) {
        this.setState({
          signersLoading: false,
          masterKeyLoading: false,
          signersError: e,
        });
      }
    }
  };

  reissue = async (request: InitRegenerationRequest) => {
    if (request.k > request.key_holders.length) {
      return;
    }

    await initRegeneration(request);
    await this.updateStatus();
  };

  activate = async (request: string) => {
    await activateSigners(request);
    await this.updateStatus();
  };

  confirm = async (request: string) => {
    await confirmKeyPart(request);
    await this.updateStatus();
  };

  componentDidMount() {
    this.props.adminDataProvider &&
      this.props.adminDataProvider().then((admin) => {
        this.setState({ admin });
      });
    this.updateStatus();
  }

  render() {
    return (
      <Content>
        <Title level={5}>Admin</Title>
        <Text>
          <Tag>{this.state.admin?.username}</Tag>
          {this.state.admin?.adminRoles.map((role) => (
            <Tag color="processing">{role}</Tag>
          ))}
        </Text>
        <br />
        <br />

        <Title level={5}>Signers</Title>
        <Signers
          loading={this.state.signersLoading}
          data={this.state.signers}
          error={this.state.signersError}
        />

        <br />
        <br />
        <Title level={5}>Master Key</Title>
        <MasterKey
          reissued={
            this.state.admin &&
            this.state.admin?.adminRoles.indexOf("super_admin") >= 0
          }
          activated={
            !this.state.signers.reduce((acc: boolean, el) => {
              return acc && el.status === "SIGNER_ACTIVE";
            }, true)
          }
          status={this.state.masterKeyStatus}
          loading={this.state.masterKeyLoading}
          onReissue={this.reissue}
          onActivate={this.activate}
          onConfirm={this.confirm}
        />
      </Content>
    );
  }
}

export default StatusPage;
