import {ComponentPropsWithRef} from "react";
import {AmplifyAuthenticator} from "@aws-amplify/ui-react";
import {AuthState} from "@aws-amplify/ui-components";
import Auth from "@aws-amplify/auth";

export const AUTH_SOURCE_KEY = "amplify-auth-source";

export const AmplifyAuthenticatorProps = {
    initialAuthState: AuthState.SignIn,
    usernameAlias: "email",
} as ComponentPropsWithRef<typeof AmplifyAuthenticator>;

export const CognitoSettings = {
    aws_project_region: "eu-central-1",
    aws_cognito_identity_pool_id: "eu-central-1_6Bo3FEwt5",
    aws_cognito_region: "eu-central-1",
    aws_user_pools_id: "eu-central-1_6Bo3FEwt5",
    aws_user_pools_web_client_id: "7l8bv0kmvrb4s4n1topofh9d80",
    oauth: {},
};

export const CognitoLogout = async () => {
    localStorage.removeItem(AUTH_SOURCE_KEY);

    if (!Auth || typeof Auth.signOut !== "function") {
        throw new Error(
            "No Auth module found, please ensure @aws-amplify/auth is imported"
        );
    }
    return await Auth.signOut();
};

export interface CognitoUser {
    username: string;
}
